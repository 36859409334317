import { appSettings } from "@/appSettings";
import BaseStoreBuilder from "@/store/base/BaseStoreBuilder";

const races = new BaseStoreBuilder({
  baseURL: appSettings.BaseUrl,
  entityName: "race"
})
  .getEntitiesList()
  .get({ path: ({ personId }) => `races/person-overview-results/${personId}`, }, 'racesOverview', [])
  .build();

export default races;